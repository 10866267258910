import React from "react"
import { StaticQuery, graphql } from "gatsby"

import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)

const Tweets = () => (
  <StaticQuery
    query={graphql`
      {
        allTwitterStatusesUserTimelineJohnrigbymusic(
          limit: 10
          sort: { fields: [created_at], order: ASC }
        ) {
          edges {
            node {
              created_at
              full_text
              id_str
            }
          }
        }
      }
    `}
    render={data =>
      data.allTwitterStatusesUserTimelineJohnrigbymusic.edges.map(tweet => {
        return (
          <div key={tweet.node.id_str} className="pb-2 flex">
            <div className="bg-purple-300 p-2 rounded-lg">
              <div className="font-bold text-xs tracking-wide">
                {tweet.node.full_text}
              </div>
              <span className="text-xs">
                <a
                  href={`https://twitter.com/johnrigbymusic/status/${tweet.node.id_str}`}
                >
                  {dayjs(tweet.node.created_at).fromNow()}
                </a>
              </span>
            </div>
          </div>
        )
      })
    }
  />
)

export default Tweets
