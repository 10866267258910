import { Link } from "gatsby"
import React, { useState } from "react"

import logo from "../images/logo.svg"

const Navbar = ({}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  return (
    <nav className="flex sm:items-baseline">
      <div className="sm:hidden mt-2 mr-2">
        {menuIsOpen ? (
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            <path d="M0 0h24v24H0z" fill="none" />
          </svg>
        ) : (
          <svg
            className="w-5 h-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
          </svg>
        )}
      </div>
      <div
        className={`${!menuIsOpen &&
          "hidden"} flex flex-col sm:block w-full sm:w-auto`}
      >
        <Link to="/" className="cursor-pointer">
          <img
            className="w-10 mr-4 my-2 hidden sm:inline-block"
            src={logo}
            alt="John Rigby Music Logo"
          />
        </Link>
        <Link
          className="mr-4 mb-2 sm:my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          to="/"
        >
          About
        </Link>
        <Link
          className="mr-4 my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          partiallyActive={true}
          to="/engagements"
        >
          Engagements
        </Link>
        <Link
          className="mr-4 my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          to="/conducting"
        >
          Conducting
        </Link>
        <Link
          className="mr-4 my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          partiallyActive={true}
          to="/supervision"
        >
          Supervision
        </Link>
        <Link
          className="mr-4 my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          to="/arrangements"
        >
          Arrangements
        </Link>
        <Link
          className="mr-4 my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          to="/academic"
        >
          Academic
        </Link>
        <Link
          className="mr-4 my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          to="/reviews"
        >
          Reviews
        </Link>
        <Link
          className="mr-4 my-2 p-2 rounded hover:underline inline-block"
          activeClassName="underline bg-purple-300 shadow"
          to="/contact"
        >
          Contact
        </Link>
      </div>

      {/* <object type="image/svg+xml" data={logo} className="inline w-8 mr-4" /> */}
    </nav>
  )
}

export default Navbar
