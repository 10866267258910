/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import Tweets from "./tweets"
import Navbar from "./navbar"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className="wrapper">
        <div className="flex-1 max-w-6xl min-h-screen p-2 sm:p-8">
          <Navbar />
          <Header siteTitle={data.site.siteMetadata.title} />
          <div className="flex mt-8">
            <main className="flex flex-col w-full lg:w-3/4">{children}</main>
            <aside className="hidden lg:block lg:w-1/4 mt-12">
              <div className="flex-1 border-2 border-purple-500 bg-purple-200 ml-1 p-3 rounded-lg">
                <Tweets />
                <a
                  className="block bg-teal-400 hover:bg-teal-500 py-2 text-center font-semibold text-sm rounded w-full hover:shadow-lg tracking-tight"
                  href="https://twitter.com/johnrigbymusic"
                >
                  Follow @johnrigbymusic
                </a>
              </div>
            </aside>

            <footer>
              {/* © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a> */}
            </footer>
          </div>
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
